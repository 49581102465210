var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('a-divider',{staticClass:"dividerTitle text-outline"},[_vm._v(" "+_vm._s(_vm.$t("pages.login.title"))+" ")]),_c('SocialLogin'),_c('div',{style:({ 'margin-left': '20px', 'margin-right': '20px' })},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('div',{staticClass:"subtitle text-outline",style:({
            'margin-left': 'auto !important',
            'margin-right': 'auto !important',
            width: 'fit-content',
          })},[_vm._v(" "+_vm._s(_vm.$t("pages.login.subtitle"))+" ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: this.$t('ui.provideValidEmail'),
                },
                {
                  required: true,
                  message: this.$t('ui.ProvideEmail'),
                },
              ],
            },
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: this.$t('ui.provideValidEmail'),\n                },\n                {\n                  required: true,\n                  message: this.$t('ui.ProvideEmail'),\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":_vm.$t('ui.email')}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [{ required: true, message: _vm.$t('ui.ProvidePassword') }],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [{ required: true, message: $t('ui.ProvidePassword') }],\n            },\n          ]"}],attrs:{"type":_vm.pwdVisible ? undefined : 'password',"placeholder":_vm.$t('ui.Password')}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"}),_c('a-icon',{attrs:{"slot":"addonAfter","type":_vm.pwdVisible ? 'eye' : 'eye-invisible'},on:{"click":function($event){_vm.pwdVisible = !_vm.pwdVisible}},slot:"addonAfter"})],1)],1),_c('a-form-item',[_c('router-link',{staticClass:"link-outline login-form-forgot",attrs:{"to":'/auth/forgot-password',"exact":"","tag":"a"}},[_vm._v(_vm._s(_vm.$t("ui.ForgotPasswordTitle")))]),_c('a-button',{staticClass:"login-form-button",attrs:{"disabled":_vm.loading,"type":"primary","html-type":"submit"}},[_vm._v(_vm._s(_vm.$t("buttons.login")))]),_c('span',{staticClass:"text-outline"},[_vm._v(_vm._s(_vm.$t("temp.or-word"))+" ")]),_c('router-link',{staticClass:"link-outline",attrs:{"to":'/auth/registration',"exact":"","tag":"a"}},[_vm._v(_vm._s(_vm.$t("temp.register-now")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }