<template>
  <section>
    <!-- <div>
      {{$installer}}
      <div v-if="$installer.canInstall">
        <a-button @click="$installer.prompt" type="primary">Install</a-button>
      </div>
      <p v-if="$installer.choice">User {{$installer.choice}} prompt.</p>
      <p v-if="$installer.hasInstalled">App installed successfully.</p>
    </div>-->
    <a-divider class="dividerTitle text-outline">
      {{ $t("pages.login.title") }}
    </a-divider>
    <!--  -->
    <SocialLogin />
    <!--  -->
    <div :style="{ 'margin-left': '20px', 'margin-right': '20px' }">
      <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="login-form"
        @submit="handleSubmit"
      >
        <a-form-item>
          <div
            class="subtitle text-outline"
            :style="{
              'margin-left': 'auto !important',
              'margin-right': 'auto !important',
              width: 'fit-content',
            }"
          >
            {{ $t("pages.login.subtitle") }}
          </div>
          <a-input
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: this.$t('ui.provideValidEmail'),
                  },
                  {
                    required: true,
                    message: this.$t('ui.ProvideEmail'),
                  },
                ],
              },
            ]"
            :placeholder="$t('ui.email')"
          >
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: $t('ui.ProvidePassword') }],
              },
            ]"
            :type="pwdVisible ? undefined : 'password'"
            :placeholder="$t('ui.Password')"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
            <a-icon
              slot="addonAfter"
              :type="pwdVisible ? 'eye' : 'eye-invisible'"
              @click="pwdVisible = !pwdVisible"
            />
          </a-input>
        </a-form-item>
        <a-form-item>
          <!-- <a-checkbox
            v-decorator="[
              'remember',
              {
                valuePropName: 'checked',
                initialValue: true
              }
            ]"
          >
            {{ $t("ui.RememberMe") }}
          </a-checkbox>-->
          <router-link
            class="link-outline login-form-forgot"
            :to="'/auth/forgot-password'"
            exact
            tag="a"
            >{{ $t("ui.ForgotPasswordTitle") }}</router-link
          >
          <a-button
            :disabled="loading"
            type="primary"
            html-type="submit"
            class="login-form-button"
            >{{ $t("buttons.login") }}</a-button
          >

          <span class="text-outline">{{ $t("temp.or-word") }}&nbsp;</span>
          <router-link
            :to="'/auth/registration'"
            exact
            tag="a"
            class="link-outline"
            >{{ $t("temp.register-now") }}</router-link
          >
        </a-form-item>
      </a-form>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import SocialLogin from "./components/SocialLogin";

export default {
  // layout: ({ isMobile }) => isMobile ? 'mobile' : 'default',
  // transition: 'slide-left',
  components: {
    SocialLogin,
  },
  middleware: "notAuthenticated",
  data() {
    return {
      pwdVisible: false,
      loading: false,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values)
          this.loading = true;
          const response = await this["authCustom/loginAction"]({
            email: values.email,
            password: values.password,
          });
          this.loading = false;
          // console.log('response: ' + response)
          if (!response) {
            this.$notification["error"]({
              message: this.$t("ui.ErrorOccurred"),
              description: false,
            });
          } else {
            this.$notification["success"]({
              message: this.$t("ui.SuccessOccurred"),
              description: false,
            });
            this.$router.push("/");
          }
        }
      });
    },
    ...mapActions(["authCustom/loginAction"]),
  },
};
</script>

<style scoped>
.login-form {
  margin: 20px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.subtitle {
  margin-bottom: 20px;
}
#components-form-demo-normal-login .login-form {
  max-width: 400px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .dividerTitle {
    display: none;
  }
}
</style>
